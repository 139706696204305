body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* table.top-performence thead tr > *, table.top-performence tbody tr > * {
  padding: 11px 20px;
} */

table.top-performence thead tr {
  background-color: white !important;
}

table.top-performence tbody tr:nth-child(odd) {
  background-color: #F9F9F9;
}

table.user thead tr {
  background-color: white !important;
}

table.user tbody tr:nth-child(odd) {
  background-color: #EFF3F6;
}


/*    File Input   */
.file-input {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  cursor: pointer;
  font-family: 'Inter';
  font-weight: 400;
  border-radius: 7.03px;
  font-size: 15.82px;
  background: #FFFFFF;
  color: #898989;
  border: 0.88px solid #DCDCDC;
  /* padding: 10px 20px; */
  text-align: center;
}

/* scroll */
.dashboard-horizontal::-webkit-scrollbar {
  height: 4px;
  border-radius: 10px;
}

.dashboard-horizontal::-webkit-scrollbar-thumb {
  background-color: #c5c3c3;
  border-radius: 10px;
}

.dashboard-horizontal::-webkit-scrollbar-track {
  background-color: #f8f6f6;
  border-radius: 10px;
}




.dashboard::-webkit-scrollbar {
  width: 4px;
  border-radius: 10px;
}

.dashboard::-webkit-scrollbar-thumb {
  background-color: #c5c3c3;
  border-radius: 10px;
}

.dashboard::-webkit-scrollbar-track {
  background-color: #f8f6f6;
  border-radius: 10px;
}



/*  chat add user icon */
.chat {
  transition: all 0.4s !important;
}

.chat:hover {
  color: #898989 !important;
}